import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as url from 'url';

import { RequiredError } from '@shared/api/base-api';
import {
  PatientPageRequestParams,
  PatientQuestionnairePageFilter,
  PatientQuestionnairePageItem,
} from '@shared/api/core-types';
import { Page } from '@shared/api/dto/Page';
import { PATH_TO_CORE_API } from '@shared/constants';
import { PatientCategories } from '@shared/constants/PatientCategories';
import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';
import { PatientState, PatientStateWithServerTime, SharingInfo } from '@shared/redux/state/patients';
import { SexType } from '@shared/types/sexTypes';
import { getServerDate } from '@shared/util/dateUtils';

/**
 * Model to edit/create a patient
 * @export
 * @interface SavePatientModel
 */
export interface SavePatientModel {
  /**
   * Additional id of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  additionalPatientId: string;
  /**
   * First name of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  firstName: string;
  /**
   * Last name of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  lastName: string;
  /**
   * Sex of the patient
   * @type {number}
   * @memberof SavePatientModel
   */
  sex: SexType;
  /**
   * Date of birth of the patient
   * @type {Date}
   * @memberof SavePatientModel
   */
  dateOfBirth: string;
  /**
   * Therapy start Date of the Patient or null if no date is specified
   * @type {Date}
   * @memberof SavePatientModel
   */
  startDateOfDataStorage: string | null;
  /**
   * Phone number of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  phone: string;
  /**
   * eMail of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  email: string;
  /**
   * Address of the patient
   * @type {string}
   * @memberof SavePatientModel
   */
  address: string;
  /**
   * The users in charge
   * @type {Array<string>}
   * @memberof SavePatientModel
   */
  usersInCharge: string[];
  /**
   * Combination of patients' categories
   * @type {number}
   * @memberof SavePatientModel
   */
  categories: PatientCategories;
  /**
   * List of organisation tenants to which the patient is sharing with.
   * @type {Array<string>}
   * @memberof SavePatientModel
   */
  shareWith?: string[];
  /**
   * Id of patient's medical condition.
   * Could be null in case medical condition is not specified.
   * @type {number | null}
   * @memberof SavePatientModel
   */
  medicalConditionId: number | null;
}

/**
 * Represents information about amount of patients in the organisation,
 * including shared by partner organisations.
 * @export
 * @interface PatientsCountDto
 */
export interface PatientsCountDto {
  /**
   * Amount of patients in the organisation.
   * @type {number}
   * @memberof PatientsCountDto
   */
  belongsToCurrentOrganisationCount: number;
  /**
   * Amount of shared patients in the organisation shared by partner organisations.
   * @type {number}
   * @memberof PatientsCountDto
   */
  sharedByPartnerOrganisationsCount: number;
}

export type GetPatientPageResult = Page<PatientState> & { utcNow: Date };

export type GetPatientQuestionnairePageResult = Page<PatientQuestionnairePageItem>;

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_CORE_API,
};

type IServerObjectWithPatientCategories = { categories: string };

type IResultObjectWithPatientCategories = { categories: PatientCategories };

const adjustObjectWithPatientCategories = <T extends IServerObjectWithPatientCategories>(
  item: T,
): Omit<T, 'categories'> & IResultObjectWithPatientCategories => {
  return {
    ...item,
    categories: parsePatientCategories(item.categories),
  };
};

type PatientCategoryType = keyof typeof PatientCategories;

const parsePatientCategories = (categories: string): PatientCategories => {
  const roles = categories.split(',').map((x) => x.trim()) as PatientCategoryType[];
  let result: PatientCategories = PatientCategories.Unspecified;
  for (const role of roles) {
    result |= PatientCategories[role];
  }

  return result;
};

export const PatientsApi = {
  /**
   *
   * @summary Gets sequence of patients.
   * @param filterAndSorting Additional sequence filter.
   * @param options Override http request option.
   */
  async getPatientsPage(
    filterAndSorting: PatientPageRequestParams,
    options?: AxiosRequestConfig,
  ): Promise<GetPatientPageResult> {
    const response: AxiosResponse<Page<Omit<PatientState, 'categories'> & { categories: string }>> = await axios.post(
      '/api/patients/page',
      filterAndSorting,
      {
        ...defaultOpts,
        ...options,
      },
    );

    return {
      ...response.data,
      utcNow: getServerDate(response),
      items: response.data.items.map((x) => adjustObjectWithPatientCategories(x)),
    };
  },
  /**
   * @summary Gets amount of patients that fit filter criteria.
   * @param filterAndSorting Additional sequence filter.
   * @param options Override http request option.
   */
  async getPatientsCount(filterAndSorting: PatientPageRequestParams, options?: AxiosRequestConfig): Promise<number> {
    const response: AxiosResponse<number> = await axios.post('/api/patients/count', filterAndSorting, {
      ...defaultOpts,
      ...options,
    });

    const data = response.data;

    return data;
  },

  async getAllPatientsAsCsv(options?: AxiosRequestConfig) {
    options = {
      ...options,
      responseType: 'arraybuffer',
    };

    const response: AxiosResponse<ArrayBuffer> = await axios.get('/api/patients/export', {
      ...defaultOpts,
      ...options,
    });

    const data = response.data;

    return data;
  },

  /**
   *
   * @summary Gets patient data.
   * @param prismacloudPatientId Patient prismaCloud id.
   * @param scopeCategory Necessary statistics data scope will be included by specified PatientFilterCategory.
   * @param options Override http request option.
   * @throws {RequiredError}
   */
  async getSinglePatient(
    prismacloudPatientId: string | null,
    scopeCategory?: PatientFilterCategory,
    options?: AxiosRequestConfig,
  ): Promise<PatientStateWithServerTime> {
    if (prismacloudPatientId === null || prismacloudPatientId === undefined) {
      throw new RequiredError(
        'prismacloudPatientId',
        'Required parameter prismacloudPatientId was null or undefined when calling getSinglePatient.',
      );
    }

    const params = url.format({ query: { scopeCategory } });
    const response: AxiosResponse<Omit<PatientState, 'categories'> & { categories: string }> = await axios.get(
      `/api/patients/${prismacloudPatientId}${params}`,
      {
        ...defaultOpts,
        ...options,
      },
    );
    return adjustObjectWithPatientCategories({ ...response.data, utcNow: getServerDate(response) });
  },
  /**
   *
   * @summary Adds new patient.
   * @param patient New patient data.
   * @param options Override http request option.
   */
  async add(patient: SavePatientModel, options?: AxiosRequestConfig): Promise<PatientState> {
    const response: AxiosResponse<Omit<PatientState, 'categories'> & { categories: string }> = await axios.post(
      '/api/patients',
      patient,
      {
        ...defaultOpts,
        ...options,
      },
    );
    return adjustObjectWithPatientCategories(response.data);
  },
  /**
   *
   * @summary Removes the given patient.
   * @param patientPrismacloudId PrismacloudId of the patient.
   * @param options Override http request option.
   * @throws {RequiredError}
   */
  async deletePatient(patientPrismacloudId: string, options?: AxiosRequestConfig): Promise<void> {
    if (patientPrismacloudId === null || patientPrismacloudId === undefined) {
      throw new RequiredError(
        'patientPrismacloudId',
        'Required parameter patientPrismacloudId was null or undefined when calling deletePatient.',
      );
    }

    await axios.delete(`/api/Patients/${patientPrismacloudId}`, { ...defaultOpts, ...options });
  },
  /**
   *
   * @summary Edits the given patient.
   * @param prismaCloudId prisma CLOUD ID of the patient.
   * @param savePatientModel Patient information.
   * @param options Override http request option.
   * @throws {RequiredError}
   */
  async editPatient(
    prismaCloudId: string,
    savePatientModel: SavePatientModel,
    options?: AxiosRequestConfig,
  ): Promise<void> {
    if (prismaCloudId === null || prismaCloudId === undefined) {
      throw new RequiredError(
        'prismaCloudId',
        'Required parameter prismaCloudId was null or undefined when calling editPatient.',
      );
    }

    await axios.put(`/api/Patients/${prismaCloudId}`, savePatientModel, {
      ...defaultOpts,
      ...options,
    });
  },
  /**
   *
   * @summary Returns the total count of patient for the current tenant.
   * @param options Override http request option.
   */
  async getTotalCount(options?: AxiosRequestConfig): Promise<PatientsCountDto | null> {
    const response = await axios.get('/api/Patients/overallCount', { ...defaultOpts, ...options });
    return response.data;
  },
  /**
   *
   * @summary Assign device to patient.
   * @param patientPrismaCloudId Id of the patient.
   * @param deviceId Id of the device.
   * @param options Override http request option.
   * @throws {RequiredError}
   */
  async assignDevice(
    patientPrismaCloudId: string | null,
    deviceId: number,
    options?: AxiosRequestConfig,
  ): Promise<void> {
    // verify required parameter 'patientPrismaCloudId' is not null or undefined
    if (patientPrismaCloudId === null || patientPrismaCloudId === undefined) {
      throw new RequiredError(
        'patientPrismaCloudId',
        'Required parameter patientPrismaCloudId was null or undefined when calling assignDevice.',
      );
    }
    // verify required parameter 'deviceId' is not null or undefined
    if (deviceId === null || deviceId === undefined) {
      throw new RequiredError(
        'deviceId',
        'Required parameter deviceId was null or undefined when calling assignDevice.',
      );
    }

    await axios.post(
      `/api/patients/${patientPrismaCloudId}/devices`,
      { DeviceId: deviceId },
      { ...defaultOpts, ...options },
    );
  },
  /**
   *
   * @summary Removes assignment of device from patient.
   * @param patientPrismaCloudId Prismacloud Id of the patient.
   * @param options Override http request option.
   * @throws {RequiredError}
   */
  async unassignDevice(patientPrismaCloudId: string | null, options?: AxiosRequestConfig): Promise<void> {
    // verify required parameter 'patientId' is not null or undefined
    if (patientPrismaCloudId === null || patientPrismaCloudId === undefined) {
      throw new RequiredError(
        'patientPrismaCloudId',
        'Required parameter patientId was null or undefined when calling assignDevice.',
      );
    }

    await axios.delete(`/api/patients/${patientPrismaCloudId}/devices`, {
      ...defaultOpts,
      ...options,
    });
  },

  async shareWith(
    prismaCloudPatientId: string,
    shareWithTenants: string[],
    options?: AxiosRequestConfig,
  ): Promise<SharingInfo[]> {
    if (prismaCloudPatientId == null) {
      throw new RequiredError(
        'prismaCloudPatientId',
        'Required parameter prismaCloudId was null or undefined when calling shareWith.',
      );
    }

    const response: AxiosResponse<SharingInfo[]> = await axios.post(
      `/api/patients/${prismaCloudPatientId}/share`,
      shareWithTenants,
      { ...defaultOpts, ...options },
    );

    return response.data;
  },

  async getPatientQuestionnairePage(
    filters: PatientQuestionnairePageFilter,
    options?: AxiosRequestConfig,
  ): Promise<GetPatientQuestionnairePageResult> {
    const response: AxiosResponse<GetPatientQuestionnairePageResult> = await axios.post(
      'api/patientQuestionnaire/page',
      filters,
      {
        ...defaultOpts,
        ...options,
      },
    );

    return response.data;
  },
};
